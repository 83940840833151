<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_1746_30199)">
      <path d="M11.334 1.99998C11.5091 1.82488 11.7169 1.68599 11.9457 1.59123C12.1745 1.49647 12.4197 1.44769 12.6673 1.44769C12.9149 1.44769 13.1601 1.49647 13.3889 1.59123C13.6177 1.68599 13.8256 1.82488 14.0007 1.99998C14.1757 2.17507 14.3146 2.38294 14.4094 2.61172C14.5042 2.84049 14.5529 3.08569 14.5529 3.33331C14.5529 3.58093 14.5042 3.82613 14.4094 4.05491C14.3146 4.28368 14.1757 4.49155 14.0007 4.66664L5.00065 13.6666L1.33398 14.6666L2.33398 11L11.334 1.99998Z" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1746_30199">
        <rect width="16" height="16"/>
      </clipPath>
    </defs>
    </svg>
</template>

<script>
export default {
  name: 'PenIcon'
}
</script>
